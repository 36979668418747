<script setup lang="ts">
import { defineProps, ref, watch, withDefaults } from "vue";
import { OrganizerDTO } from "@/dto";

interface Props {
  modalLoading?: boolean;
  modalMessage?: string;
  roundedActionButtons?: boolean;
  width?: number | string;
  height?: number | string;
  closeButtonText?: string;
  closeIsDisabled?: boolean;
  closeDisplay?: boolean;
  titleBackgroundColor?: string;
  titleTextColor?: string;
  buttonColor?: string;
  buttonWidth?: string;
  modal?: boolean;
  pdf?: boolean;
  text?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modalLoading: false,
  roundedActionButtons: false,
  width: "1000px",
  height: "auto",
  modalMessage: "",
  closeButtonText: "Fermer",
  closeDisplay: true,
  closeIsDisabled: false,
  titleBackgroundColor: "#0096C7",
  titleTextColor: 'white',
  buttonColor: "#0096C7",
  buttonWidth: "auto",
  modal: false,
  pdf: false,
  text: "",
});

const modalComponent = ref(false);
const scrollable = ref(!props.pdf);

watch(
  () => props.modal,
  () => {
    modalComponent.value = props.modal;
  },
  { immediate: true }
);

function organizer(): OrganizerDTO {
  return useOrganizerStore().organizer;
}
</script>

<template slot="default" slot-scope="slotProps">
  <v-dialog v-model="modalComponent" :max-width="width" persistent
    :style="{ height: props.height }">
    <v-card class="pa-0">
      <v-card-title v-if="$slots.header" :style="{ backgroundColor: props.titleBackgroundColor }">
        <div class="text-h3 white--text" :style="{ color: props.titleTextColor }">
          <v-btn v-if="props.pdf" icon :color="buttonColor !== '' ? buttonColor : titleBackgroundColor"
            @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <slot name="header" />
        </div>
        <div :class="'text--darken-2 subtitle-2' + titleTextColor" style="width: 100%">
          <slot name="header-subtitle" />
        </div>
      </v-card-title>
      <v-card-text v-if="$slots.content" :class="[props.pdf ? 'pa-0' : 'pb-0 mt-2 mb-2']">
        <slot name="content" />
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-3">
        <slot name="footer-information" />
        <v-spacer />
        <v-btn size="large" v-if="closeDisplay" :color="organizer().color" :disabled="props.closeIsDisabled"
          variant="outlined" @click="$emit('close')">
          {{ closeButtonText }}
        </v-btn>
        <slot name="footer" />
      </v-card-actions>
      <slot name="error" />
      <v-alert v-if="modalMessage" dense type="error" elevation="2" class="mx-1">
        {{ modalMessage }}
      </v-alert>
    </v-card>
  </v-dialog>
</template>